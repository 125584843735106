import Card from '../Card'
import './Time.css'

const Time = (props) => {

    const background = { backgroundColor: props.corSecundaria }
    const border = { borderColor: props.corPrimaria }

    return(
        (props.colaboradores.length > 0) ? 
        <section className="time" style={background}>
            <h3 style={border}>{props.nome}</h3>
            <div className="cards">
                {props.colaboradores.map(colaborador => 
                <Card 
                key={colaborador.nome} 
                nome={colaborador.nome} 
                cargo={colaborador.cargo} 
                imagem={colaborador.imagem}
                />)}
            </div>
        </section>
        : ''
    )
}

export default Time