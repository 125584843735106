import './Banner.css'

const Banner = () => {
    return (
        <header className='banner'>
            <img src="/imagens/banner.png" alt="iamgem banner"/> 
        </header>
    )
} 

export default Banner